import {FormikContextType} from 'formik'
import moment from 'moment'
import {useCallback, useMemo, useState} from 'react'
import {useLocalTableSearch} from '../../hooks/useLocalTableSearch'
import {useOnChange} from '../../hooks/useOnChange'
import {ColumnStyle} from '../../tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../tables/TableColumn'
import {map} from 'lodash'
import {FilterTable} from '../../tables/FilterTable'
import {idExtractor} from '../../../utils/idExtractor'
import {useTableOptions} from '../../tables/useTableOptions'
import {
  SeatMapSelectionModalFormValues,
  getBulkSeatMapPayload,
} from '../../forms/SeatMapSelectionModalForm'
import {useModalState} from '../../modals/useModalState'
import {CustomersProductTableActions} from './CustomersProductTableActions'
import {useLoadingState} from '../../hooks/useLoadingState'
import {
  BookingWizardFinalSeatsStepFormValues,
  BulkBookingFormValues,
  CustomersProductProps,
  ProductVoucherBulkModel,
} from '../../../models/booking-wizard/BulkBookingWizard'

import {BookingSeatMap} from '../../BookingWizard/component/bulkBooking/BookingSeatMap'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'
import {SeatMapValue} from '../../inputs/SeatMapInput/SeatMapValue'
import {SeatTableColumn} from '../component/SeatTableColumn'
import {FinalStepProductHeader} from '../component/FinalStepProductHeader'
import {DateUtil} from '../../../utils/DateUtil'
import {dateIsBetween} from './BookingWizardSharedFinalizeStep'
import {DateRange} from '../../../utils/DateRange'
import {NonSeatedTimeslotDateModalForm} from '../BookingWizardTables/modals/NonSeatedTimeSlotDateModalForm'
import {useAlerts} from '../../alerts/useAlerts'

export interface BookingWizardSharedBulkFinalizeStepProps<
  T extends BookingWizardFinalSeatsStepFormValues
> {
  formik: FormikContextType<T>
  bookingBulkForm?: BulkBookingFormValues
  customerCode?: string
}

export const BookingWizardSharedBulkFinalizeStep = <
  T extends BookingWizardFinalSeatsStepFormValues
>({
  formik,
  bookingBulkForm,
  customerCode,
}: BookingWizardSharedBulkFinalizeStepProps<T>) => {
  const {hiddenColumns, setHiddenColumns} = useTableOptions({
    tableName: 'product-booking-detail',
  })

  const [productsSeats, setProductsSeats] = useState<TicketModelFulfillBulkParams[]>([])
  const [activeProduct, setActiveProduct] = useState<CustomersProductProps | null>(null)
  const {getModalState, open: openModal, hide: hideModal} = useModalState()
  const [isOpenn, setIsOpen] = useState<boolean>(false)
  const {isKeyLoading} = useLoadingState()
  const {pushError} = useAlerts()

  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      formik.setFieldValue('customers', bookingBulkForm.customers)
      formik.setFieldValue('product', bookingBulkForm.product)
      formik.setFieldValue('voucher', bookingBulkForm.voucher)
      formik.setFieldValue('eventCode', bookingBulkForm.eventCode)
      formik.setFieldValue('customersSeats', bookingBulkForm.customersSeats)
    }
  })

  const selectedSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.find(
        (item) =>
          item.customerCode === activeProduct.code &&
          (dateIsBetween(
            DateUtil.getDateFromApiString(item.startedAt),
            DateUtil.getDateFromApiString(activeProduct.startedAt),
            DateUtil.getDateFromApiString(activeProduct.endedAt)
          ) ||
            dateIsBetween(
              DateUtil.getDateFromApiString(item.endedAt),
              DateUtil.getDateFromApiString(activeProduct.startedAt),
              DateUtil.getDateFromApiString(activeProduct.endedAt)
            ))
      )
      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats])

  const otherSeats = useMemo(() => {
    if (formik.values.customersSeats && formik.values.customersSeats.length && activeProduct) {
      const found = formik.values.customersSeats.filter(
        (item) => item.customerCode !== activeProduct.code
      )
      if (found) return found
    }
    return undefined
  }, [activeProduct, formik.values.customersSeats])

  const isProduct = useMemo(() => {
    return formik.values.product
  }, [formik.values.product])

  const customersProductsVouchers = useMemo<CustomersProductProps[] | null>(() => {
    if (formik.values.customers && formik.values.customers.length) {
      const data: CustomersProductProps[] = formik.values.customers.map((customer) => {
        return {
          code: customer.code,
          name: customer.name,
          productCode:
            (isProduct ? formik.values.product?.code : formik.values.voucher?.code) || '',
          productName:
            (isProduct ? formik.values.product?.name : formik.values.voucher?.name) || '',
          productType:
            (isProduct ? formik.values.product?.type : formik.values.voucher?.type) || 'product',
          productQty: formik.values.product?.qty || 0,
          productIsSeated: formik.values.product?.isSeated || false,
          startedAt: formik.values.product?.startedAt || '',
          endedAt: formik.values.product?.endedAt || '',
          isTimeslot: formik.values.product?.isTimeslot || false,
        }
      })
      if (data) {
        return data
      }
    }

    if (formik.values.customer) {
      return [
        {
          code: formik.values.customer.code,
          name: formik.values.customer.name,
          productCode:
            (isProduct ? formik.values.product?.code : formik.values.voucher?.code) || '',
          productName:
            (isProduct ? formik.values.product?.name : formik.values.voucher?.name) || '',
          productType:
            (isProduct ? formik.values.product?.type : formik.values.voucher?.type) || 'product',
          productQty: formik.values.product?.qty || 0,
          productIsSeated: formik.values.product?.isSeated || false,
          startedAt: formik.values.product?.startedAt || '',
          endedAt: formik.values.product?.endedAt || '',
          isTimeslot: formik.values.product?.isTimeslot || false,
        },
      ]
    }
    return null
  }, [
    formik.values.customer,
    formik.values.customers,
    formik.values.product?.code,
    formik.values.product?.endedAt,
    formik.values.product?.isSeated,
    formik.values.product?.name,
    formik.values.product?.qty,
    formik.values.product?.startedAt,
    formik.values.product?.type,
    formik.values.product?.isTimeslot,
    formik.values.voucher?.code,
    formik.values.voucher?.name,
    formik.values.voucher?.type,
    isProduct,
  ])

  const columns = useMemo(() => {
    const columns: TableColumnOptions<CustomersProductProps>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        cellStyle: ColumnStyle.CODE,
      },
      {
        field: 'name',
        label: 'Customer Name',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
      },
      {
        field: 'customerSeats',
        label: 'Seats',
        sortable: true,
        hideable: true,
        render: ({data}) => {
          return <SeatTableColumn customersSeats={formik.values.customersSeats} data={data} />
        },
      },
    ]
    return columns
  }, [formik.values.customersSeats])

  const {searchableLocalTableData, filterSearchableLocalTableData} = useLocalTableSearch({
    data: customersProductsVouchers,
    columns: customersProductsVouchers
      ? (map(columns, 'field') as Array<keyof CustomersProductProps>)
      : null,
    additionColumns: [
      'productType',
      'productCode',
      'productName',
      'productQty',
      'productIsSeated',
      'startedAt',
      'endedAt',
      'isTimeslot',
    ],
  })

  const getSeatAssignmentHandler = useCallback(
    async (data: CustomersProductProps) => {
      setActiveProduct(data)
      openModal()
    },
    [openModal]
  )

  const onFulfillNonSeatedProduct = useCallback(async (data: CustomersProductProps) => {
    setActiveProduct(data)
    setIsOpen(true)
  }, [])

  const minDate = useMemo(() => {
    const today = moment().startOf('day')
    if (activeProduct) {
      const startedAt = DateUtil.getDateFromApiString(activeProduct.startedAt)
      return moment.max(moment(startedAt), today).toDate()
    }
  }, [activeProduct])

  const maxDate = useMemo(() => {
    if (activeProduct) {
      return DateUtil.getDateFromApiString(activeProduct.endedAt)
    }
  }, [activeProduct])

  const initialValueDateRange = useMemo(() => {
    const selSeats = formik.values?.customersSeats?.find(
      (seat) => seat.customerCode === activeProduct?.code
    )
    if (activeProduct && selSeats) {
      return new DateRange(
        DateUtil.getDateFromApiString(selSeats.startedAt),
        DateUtil.getDateFromApiString(selSeats.endedAt)
      )
    }
    return new DateRange()
  }, [activeProduct, formik.values.customersSeats])

  const initialValueDateRangeArray = useMemo(() => {
    if (activeProduct) {
      let timeSlots = []
      let newProductsValues = formik.values.product

      if (newProductsValues?.timeslots && newProductsValues?.timeslots.length) {
        const timeslotInd = newProductsValues?.timeslots.findIndex(
          (t) => t.customerCode === activeProduct.code
        )
        if (timeslotInd >= 0) {
          if (Array.isArray(newProductsValues.timeslots[timeslotInd].timeslot)) {
            for (const timeslot of newProductsValues.timeslots[timeslotInd].timeslot) {
              timeSlots.push({
                startedAt: timeslot.startedAt,
                endedAt: timeslot.endedAt,
              })
            }
            return timeSlots
          }
        }
      }
    }
    return []
  }, [activeProduct, formik])

  const rowActions = useCallback(
    (data: CustomersProductProps) => (
      <CustomersProductTableActions
        data={data}
        onAssignSeats={getSeatAssignmentHandler}
        onFulfillNonSeatedProduct={onFulfillNonSeatedProduct}
        // onFulfillVoucher={fulfillVoucher}
        loading={isKeyLoading(data.code)}
      />
    ),
    [getSeatAssignmentHandler, onFulfillNonSeatedProduct, isKeyLoading]
  )

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  useOnChange(productsSeats, () => {
    formik.setFieldValue('customersSeats', productsSeats)
  })

  const handleSeatsSelection = useCallback(
    async (values: SeatMapSelectionModalFormValues) => {
      try {
        let startDate: string = ''
        let endDate: string = ''
        if (activeProduct && activeProduct.productCode) {
          if (activeProduct.isTimeslot && values.dateRange) {
            startDate = DateUtil.convertDateToApiString(values.dateRange?.getStartOrFail())
            endDate = DateUtil.convertDateToApiString(values.dateRange?.getEndOrFail())
          }

          const payload = getBulkSeatMapPayload(
            values,
            activeProduct.productCode,
            activeProduct.code,
            activeProduct.isTimeslot ? startDate : activeProduct.startedAt,
            activeProduct.isTimeslot ? endDate : activeProduct.endedAt
          )

          if (payload) {
            let newSeates = [...productsSeats]
            newSeates = newSeates.filter((item) => item.customerCode !== payload.customerCode)
            newSeates.push(payload)
            setProductsSeats(newSeates)
            hideModal()
          }
        }
      } catch (e) {
        pushError(e)
      }
    },
    [activeProduct, hideModal, productsSeats, pushError]
  )

  const handleNonseatedDates = useCallback(
    async (dates: DateRange[]) => {
      if (activeProduct && activeProduct.productCode && dates.length) {
        const prodValues = formik.values.product
        let timeSlotArray = []

        for (const d of dates) {
          timeSlotArray.push({
            startedAt: DateUtil.convertDateToApiString(d?.getStartOrFail()),
            endedAt: DateUtil.convertDateToApiString(d?.getEndOrFail()),
          })
        }

        if (prodValues?.timeslots?.length) {
          const timeslotInd = prodValues?.timeslots.findIndex(
            (t) => t.customerCode === activeProduct.code
          )
          const newValues: ProductVoucherBulkModel = {
            name: activeProduct.name,
            code: activeProduct.productCode,
            qty: activeProduct.productQty,
            isSeated: activeProduct.productIsSeated,
            type: activeProduct.productType,
            startedAt: activeProduct.startedAt || '',
            endedAt: activeProduct.endedAt || '',
            isTimeslot: activeProduct.isTimeslot,
          }
          if (timeslotInd >= 0) {
            prodValues.timeslots[timeslotInd] = {
              customerCode: activeProduct.code,
              timeslot: timeSlotArray,
            }
            newValues.timeslots = prodValues.timeslots
          } else {
            prodValues.timeslots.push({
              customerCode: activeProduct.code,
              timeslot: timeSlotArray,
            })
            newValues.timeslots = prodValues.timeslots
          }

          formik.setFieldValue('product', newValues)
        } else {
          const newValues: ProductVoucherBulkModel = {
            name: activeProduct.name,
            code: activeProduct.productCode,
            qty: activeProduct.productQty,
            isSeated: activeProduct.productIsSeated,
            type: activeProduct.productType,
            startedAt: activeProduct.startedAt || '',
            endedAt: activeProduct.endedAt || '',
            isTimeslot: activeProduct.isTimeslot,
            timeslots: [
              {
                customerCode: activeProduct.code,
                timeslot: timeSlotArray,
              },
            ],
          }

          formik.setFieldValue('product', newValues)
        }
      }
      setIsOpen(false)
    },
    [formik.values.product, activeProduct]
  )

  return (
    <div className='container'>
      <div>
        <FinalStepProductHeader product={formik.values.product} />
        <FilterTable
          onFilter={filterSearchableLocalTableData}
          idExtractor={idExtractor}
          onHiddenColumnsChange={setHiddenColumns}
          hiddenColumns={hiddenColumns}
          data={searchableLocalTableData.data}
          currentPageNumber={searchableLocalTableData?.page}
          columns={columns}
          totalItems={searchableLocalTableData?.total}
          actions={rowActions}
        />
        <BookingSeatMap
          onSubmit={handleSeatsSelection}
          product={activeProduct}
          eventCode={formik.values.eventCode}
          customerCode={customerCode}
          selectedSeat={selectedSeats}
          otherSeats={otherSeats}
          initialValues={{
            locationCode: selectedSeats?.locationCode || '',
            selected: selectedSeats?.seats || new SeatMapValue(),
            dateRange: initialValueDateRange,
          }}
          {...getModalState()}
        />

        <NonSeatedTimeslotDateModalForm
          minDate={minDate}
          maxDate={maxDate}
          qty={activeProduct ? activeProduct.productQty : 0}
          onSubmit={handleNonseatedDates}
          open={isOpenn}
          onHide={handleCloseModal}
          initialDates={initialValueDateRangeArray}
        />
      </div>
    </div>
  )
}
