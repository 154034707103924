import {useMemo} from 'react'
import moment from 'moment'
import {DateUtil} from '../../../utils/DateUtil'

export interface DateFormatterProps {
  className?: string
  children: Date | string | null
  moment?: {
    parse?: string
    format: string
  }
}

export const DateFormatter = ({children, className, moment: momentProp}: DateFormatterProps) => {
  const momentParse = momentProp?.parse
  const momentFormat = momentProp?.format
  const dateString = useMemo(() => {
    if (!children) {
      return ''
    }
    if (momentFormat) {
      return moment(children, momentParse).format(momentFormat)
    }
    return DateUtil.formatToDateString(children)
  }, [children, momentFormat, momentParse])

  return <time className={className}>{dateString}</time>
}
