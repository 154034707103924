import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as acs from '../../app/modules/default/acs/redux/AcsRedux'
import * as auth from '../../app/modules/default/auth'
import * as svc from '../../app/modules/default/svc/redux/SvcRedux'
import * as ems from '../../app/modules/default/ems/redux/EmsRedux'
import * as fnb from '../../app/modules/default/fnb/redux/FnbRedux'
import * as system from '../../app/modules/default/system/redux/SystemRedux'
import * as outlet from '../../app/modules/outlet/redux/OutletRedux'
import * as customerPortal from '../../app/modules/customer-portal/redux/CustomerPortalRedux'
import * as poken from '../../app/modules/poken/redux/PokenRedux'
import * as digitalMenu from '../../app/modules/digital-menu/redux/DigitalMenuRedux'
import * as worker from './SetupServiceWorker'
import * as eva from '../../app/modules/eva/redux/EvaRedux'
import * as config from '../../app/modules/app-config/redux/AppConfigRedux'
import * as customerDelegates from '../../app/modules/customer-delegate/redux/CustomerDelegateRedux'
import * as merchandise from '../../app/modules/default/merchandise/redux/MerchandiseRedux'
import * as retail from '../../app/modules/default/retail/redux/RetailRedux'

export const rootReducer = combineReducers({
  acs: acs.reducer,
  auth: auth.reducer,
  svc: svc.reducer,
  ems: ems.reducer,
  fnb: fnb.reducer,
  system: system.reducer,
  outlet: outlet.reducer,
  eva: eva.reducer,
  customerPortal: customerPortal.reducer,
  poken: poken.reducer,
  digitalMenu: digitalMenu.reducer,
  worker: worker.reducer,
  config: config.reducer,
  customerDelegates: customerDelegates.reducer,
  merchandise: merchandise.reducer,
  retail: retail.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    acs.saga(),
    auth.saga(),
    svc.saga(),
    ems.saga(),
    fnb.saga(),
    eva.saga(),
    system.saga(),
    outlet.saga(),
    customerPortal.saga(),
    config.saga(),
    customerDelegates.saga(),
    poken.saga(),
    merchandise.saga(),
    retail.saga(),
  ])
}
