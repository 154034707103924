import {ApplicationModel} from '../../models/ApplicationModel'
import {FeatureModel} from '../../models/FeatureModel'
import {Feature, FeatureCode} from './Feature'

export type ApplicationCode = 'EMS' | 'ACS' | 'FNB' | 'SVC' | 'EVA' | 'CMS' | 'MERCHANDISE' | 'SETTINGS'

export const FeatureApplicationMap: Record<FeatureCode, ApplicationCode> = {
  ACSGATES: 'ACS',
  ACSLOCATION: 'ACS',
  ACSLOGS: 'ACS',
  ACSVENUES: 'ACS',
  EMSACTIVITY: 'EMS',
  EMSBOOKINGS: 'EMS',
  EMSBUNDLE: 'EMS',
  EMSEVENTS: 'EMS',
  EMSPRODCAT: 'EMS',
  EMSPRODUCT: 'EMS',
  EMSTICKETS: 'EMS',
  EMSGUESTS: 'EMS',
  EMSRESERVATIONS: 'EMS',
  EMSBADGECOLLECTION: 'EMS',
  EMSWORKSHOPS: 'EMS',
  EMSPOKENS: 'EMS',
  EMSNOTIFICATIONS: 'EMS',
  EMSBADGE: 'EMS',
  EMSCHANNEL: 'EMS',
  EVAAGENDA: 'EVA',
  EVASPEAKERS: 'EVA',
  EVATOPICS: 'EVA',
  EVAPOLLS: 'EVA',
  FNBIMPORT: 'FNB',
  FNBLOGS: 'FNB',
  FNBORDERS: 'FNB',
  FNBOUTLETS: 'FNB',
  FNBPRODCATS: 'FNB',
  FNBPRODUCTS: 'FNB',
  FNBQRGEN: 'FNB',
  FNBTABLERES: 'FNB',
  FNBUSERS: 'FNB',
  FNBINVENTORY: 'FNB',
  SVCCUST: 'SVC',
  SVCCUSTCATS: 'SVC',
  SVCDASHBOARD: 'SVC',
  SVCIMPORT: 'SVC',
  SVCTEMPLATE: 'SVC',
  SVCVOUCHER: 'SVC',
  SVCWORKFLOW: 'SVC',
  CMSPOSTS: 'CMS',
  CMSFILES: 'CMS',
  CMSPAGES: 'CMS',
  CMSFAQS: 'CMS',
  CMSLIBRARY: 'CMS',
  CMSPARTNER: 'CMS',
  MERCHANDISEOUTLETS: 'MERCHANDISE',
  MERCHANDISEPRODCATS: 'MERCHANDISE',
  MERCHANDISEPRODUCTS: 'MERCHANDISE',
  MERCHANDISEORDERS: 'MERCHANDISE',
  MERCHANDISEUSERS: 'MERCHANDISE',
  MERCHANDISELOGS: 'MERCHANDISE',
  EMSTEAMTYPES: 'EMS',
  EMSTEAMS: 'EMS',
  EMSTEAMMEMBERS: 'EMS',
  EMSDEVICES: 'EMS',
  VOUCHERCASHIN: 'SVC',
  VOUCHERCASHOUT: 'SVC',
  SVCWORKFLOWBUILDERLOGS: 'SVC',
  SVCWORKFLOWBUILDER: 'SVC',
  EMSRETAIL: 'EMS',
}

export class Application {
  private features = new Map<string, Feature>()

  constructor(private application: ApplicationModel) {
    if (application.features) {
      application.features.forEach((feature) => {
        this.addFeature(feature)
      })
    }
  }

  public getCode() {
    return this.application.code as ApplicationCode
  }

  public getName() {
    return this.application.name
  }

  public addFeature(feature: FeatureModel) {
    this.features.set(feature.code, new Feature(feature))
  }

  public getFeature(featureCode: string): Feature | undefined {
    return this.features.get(featureCode)
  }

  public getFeatureOrFail(featureCode: string): Feature {
    const found = this.getFeature(featureCode)
    if (!found) {
      throw new Error(`Feature ${featureCode} is not found.`)
    }
    return found
  }

  public getAllFeatures(): Feature[] {
    const features: Feature[] = []
    this.features.forEach((feature) => {
      features.push(feature)
    })
    return features
  }

  public hasAnyFeatures(): boolean {
    return this.features.size > 0
  }
}
