import {ChangeEvent, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {FilterModel} from '../../../models/FilterModel'
import {FilterSearchableSelectInput} from '../SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../SelectInput'
import {MetronicIconButton} from '../MetronicIconButton'
import {DateRange} from '../../../utils/DateRange'
import {NonSeatedProductTimeSlot} from '../../../models/ems/BookingModel'

export interface ListCountInputItemValue<T> {
  data: T | null
  count: number
  minCount?: number
  id: number | string
  isNew?: boolean
  type?: 'product' | 'voucher'
  startDate?: string
  endDate?: string
  timeslots?: NonSeatedProductTimeSlot[]
}

export interface ListCountInputItemProps<T> {
  className?: string
  value: ListCountInputItemValue<T>
  onChange: (value: ListCountInputItemValue<T>) => void
  onSearch: (filter: FilterModel) => void
  searchResult?: GlobalSearchModel<T>
  selectedItems: ListCountInputItemValue<T>[]
  placeholder: string
  label: string
  onRemove: (value: ListCountInputItemValue<T>) => void
  itemMapper: (data: T) => SelectInputItem
  disabled?: boolean
  isMinCount?: boolean
}

export const ListCountInputItem = <T,>({
  searchResult,
  onChange,
  onSearch,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
  itemMapper,
  disabled,
  isMinCount,
}: ListCountInputItemProps<T>) => {
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ListCountInputItemValue<T> = {...value, count: Number(count)}
      onChange(newValue)
    },
    [onChange, value]
  )

  const handleSelectionChange = useCallback(
    (item: T | null) => {
      if (item) {
        onChange({...value, data: item})
      }
    },
    [value, onChange]
  )

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const filteredSearchResult = useMemo(() => {
    if (searchResult) {
      return {
        ...searchResult,
        data: searchResult.data.filter(
          (item) =>
            !selectedItems.some(
              (selectedItem) =>
                selectedItem.data && itemMapper(item).value === itemMapper(selectedItem.data).value
            )
        ),
      }
    }
  }, [itemMapper, searchResult, selectedItems])

  return (
    <div className={clsx('product-input-item d-flex flex-column gap-5', className)}>
      <label className='form-label'>{label}</label>
      <div className='product-input-item-input-container'>
        <div className='flex-grow-1  d-flex flex-column gap-5'>
          <div className='w-100' style={{minWidth: 0}}>
            <FilterSearchableSelectInput
              disabled={disabled}
              value={value.data}
              itemMapper={itemMapper}
              searchResult={filteredSearchResult}
              placeholder={placeholder}
              onChange={handleSelectionChange}
              onFilter={onSearch}
              noMargin
            />
          </div>
        </div>
        <MetronicIconButton
          type='button'
          className='btn btn-icon btn-active-color-danger'
          iconType='General'
          iconName='Trash'
          variant='text'
          size='sm'
          onClick={handleRemove}
          tooltip='Delete'
        />
      </div>
      <div className='d-flex gap-5'>
        <div>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            disabled={disabled || !value.data}
            value={value.count}
            min={0}
          />
        </div>
        {isMinCount && (
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            disabled={disabled || !value.data || value.count === value.minCount}
            value={value.minCount}
            min={0}
          />
        )}
      </div>
    </div>
  )
}
