import {BookingFormValues} from '../../../models/booking-wizard/BookingWizard'
import {BookingModelCreateParams} from '../../../models/ems/BookingModel'
import * as yup from 'yup'

export const bookingWizardValidationSchema = yup.object().shape({
  eventCode: yup.string().required(),
  customer: yup.object().required(),
  products: yup
    .array()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as BookingFormValues

      if (
        formValues.vouchers.length > 0 &&
        formValues.vouchers.some((item) => item.data && item.count > 0)
      ) {
        return true
      }
      if (!value) {
        return false
      }

      return value.some((item) => {
        return Boolean(item.data) && item.count > 0
      })
    }),

  vouchers: yup
    .array()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as BookingFormValues

      if (
        formValues.products.length > 0 &&
        formValues.products.some((item) => item.data && item.count > 0)
      ) {
        return true
      }
      if (!value) {
        return false
      }

      return value.some((item) => {
        return Boolean(item.data) && item.count > 0
      })
    }),

  customersSeats: yup
    .array()
    .test('has-atleast-one-seat', 'Please add at least one Seat', (value, context) => {
      const formValues = context.parent as BookingFormValues

      const filteredProducts = formValues.products.filter((item) => item.data?.isSeated)
      if (filteredProducts.length > 0) {
        if (value && filteredProducts.length === value.length) {
          return true
        }
        return false
      }
      return true
    }),
})

export const EMPTY_FORM_VALUES: BookingFormValues = {
  customer: null,
  eventCode: '',
  products: [],
  vouchers: [],
  customersSeats: [],
}

export const getPayload = (values: BookingFormValues): BookingModelCreateParams => {
  if (!values.customer) {
    throw new Error('Invalid form data.')
  }
  const payload: BookingModelCreateParams = {
    customerCode: values.customer.code,
    eventCode: values.eventCode || '',
    products: [],
    bundles: [],
    vouchers: [],
  }
  values.products.forEach((item) => {
    if (item.data && item.count > 0) {
      const found =
        item.data.isSeated && values.customersSeats && values.customersSeats?.length > 0
          ? values.customersSeats.find((seat) => seat.productCode === item.data?.code)
          : null
      if (item.data.isTimeslot) {
        if (item.data.isSeated) {
          payload.products.push(
            found
              ? {
                  code: item.data.code,
                  qty: item.count,
                  locationCode: found.locationCode,
                  seats: found.seats.getSeatMapObject(),
                  startedAt: item.startDate ? item.startDate : item.data.productCategory?.startedAt,
                  endedAt: item.endDate ? item.endDate : item.data.productCategory?.endedAt,
                }
              : {code: item.data.code, qty: item.count}
          )
        } else {
          if (!item.timeslots?.length) {
            throw new Error('Please add start & end dates.')
          }
          payload.products.push({
            code: item.data.code,
            qty: item.count,
            timeslots: item.timeslots,
          })
        }
      } else {
        payload.products.push(
          found
            ? {
                code: item.data.code,
                qty: item.count,
                locationCode: found.locationCode,
                seats: found.seats.getSeatMapObject(),
              }
            : {code: item.data.code, qty: item.count}
        )
      }
    }
  })
  values.vouchers.forEach((item) => {
    if (item.data && item.count > 0) {
      payload.vouchers.push({code: item.data.code, qty: item.count})
    }
  })
  return payload
}
