import {FormikContextType} from 'formik'
import {useCallback, useMemo, useState} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {TableRowId} from '../../tables/TableRow'
import {CustomerWizardTable} from '../BookingWizardTables/CustomerWizardTable'
import {useOnChange} from '../../hooks/useOnChange'
import {Button} from '../../inputs/Button'
import {
  BookingWizardBulkCustomerStepFormValues,
  BulkBookingFormValues,
} from '../../../models/booking-wizard/BulkBookingWizard'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {CustomerModel} from '../../../models/CustomerModel'

export interface BookingWizardSharedBulkCustomerStepProps<
  T extends BookingWizardBulkCustomerStepFormValues
> {
  formik: FormikContextType<T>
  disabledFields?: Partial<Record<keyof BookingWizardBulkCustomerStepFormValues, boolean>>
  isEdit?: boolean
  onNoCustonmers?: (is: boolean) => void
  onStepChange?: (step: number) => void
  bookingBulkForm?: BulkBookingFormValues
  customers?: GlobalSearchModel<CustomerModel>
  searchCustomers: (filter?: FilterModel) => void
}

export const BookingWizardSharedBulkCustomerStep = <
  T extends BookingWizardBulkCustomerStepFormValues
>({
  formik,
  isEdit,
  onNoCustonmers,
  onStepChange,
  bookingBulkForm,
  customers,
  searchCustomers,
}: BookingWizardSharedBulkCustomerStepProps<T>) => {
  const [isStart, setIsStart] = useState<boolean>(false)
  useOnChange(bookingBulkForm, () => {
    if (bookingBulkForm) {
      if (bookingBulkForm.customers) formik.setFieldValue('customers', bookingBulkForm.customers)
    }
  })

  const selectedItems = useMemo(() => {
    return formik.values?.customers ? formik.values.customers.map((item) => item.code) : []
  }, [formik.values.customers])

  const handleOnRowSelectionChange = useCallback(
    async (rows: TableRowId[]) => {
      if (isStart) {
        const foundData = customers?.data.filter((customer) => {
          return rows.find((item) => customer.code === item)
        })
        if (foundData) {
          if (foundData === formik.values.customers) {
            formik.setFieldValue('customers', '')
          } else {
            if (formik.values.customers?.length) {
              const selectedCustomerItems = formik.values.customers
              let listCustomer: CustomerModel[] = []
              selectedCustomerItems.map((customer) => {
                listCustomer.push({
                  avatarCode: customer.avatarCode,
                  code: customer.code,
                  email: customer.email,
                  isFirstLogin: customer.isFirstLogin,
                  firstName: customer.firstName,
                  lastName: customer.lastName,
                  name: customer.name,
                  status: customer.status,
                  type: customer.type,
                  originalEmail: customer.originalEmail || '',
                })
                return null
              })
              foundData.map((customer) => {
                const findIndx = listCustomer.findIndex((i) => i.code === customer.code)
                if (findIndx === -1) {
                  listCustomer.push({
                    avatarCode: customer.avatarCode,
                    code: customer.code,
                    email: customer.email,
                    isFirstLogin: customer.isFirstLogin,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    name: customer.name,
                    status: customer.status,
                    type: customer.type,
                    originalEmail: customer.originalEmail || '',
                  })
                }
                return null
              })
              const filterCustomer = listCustomer.filter((customer) => {
                return rows.find((item) => customer.code === item)
              })
              formik.setFieldValue('customers', filterCustomer)
            } else {
              formik.setFieldValue('customers', foundData)
            }
          }
        }
      }
    },
    [customers?.data, formik, isStart]
  )

  useOnChange(formik.values.customers, () => {
    if (!isStart) {
      if (formik.values.customers) {
        setTimeout(() => {
          setIsStart(true)
          formik.setFieldValue('customers', formik.values?.customers)
        }, 1000)
      } else {
        setTimeout(() => {
          setIsStart(true)
        }, 1000)
      }
    }
  })

  const onFilterHandler = useCallback(
    (filter: FilterModel) => {
      searchCustomers(filter)
    },
    [searchCustomers]
  )

  const isCustomer = useMemo(() => {
    const noCustomer = customers?.data && customers.data.length > 0

    return noCustomer
  }, [customers?.data])

  useOnChange(isCustomer, () => {
    if (!isCustomer) onNoCustonmers && onNoCustonmers(true)
    else if (isCustomer) onNoCustonmers && onNoCustonmers(false)
  })

  const customersTable = useMemo(() => {
    return (
      <div>
        <CustomerWizardTable
          onFilter={onFilterHandler}
          data={customers}
          hideSelectAll={true}
          selectedItems={selectedItems}
          onRowSelectionChange={handleOnRowSelectionChange}
          isEdit={isEdit}
          isBulk={true}
        />
        {!isCustomer && (
          <div className='text-center'>
            <Button
              variant='primary'
              className='btn'
              onClick={() => onStepChange && onStepChange(1)}
            >
              Create new customer
            </Button>
          </div>
        )}
      </div>
    )
  }, [
    customers,
    handleOnRowSelectionChange,
    isCustomer,
    isEdit,
    onFilterHandler,
    onStepChange,
    selectedItems,
  ])

  return (
    <>
      <div className='container'>
        <div className='row g-5'>
          <div className='col-12'>
            <h5 className='mt-10 mb-0 px-8'>Select a customer</h5>
          </div>
          {customersTable}
        </div>
      </div>
    </>
  )
}
