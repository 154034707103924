import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {FilterSearchableSelectInput} from '../../../inputs/SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../../../inputs/SelectInput'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {ProductVoucherBulkModel} from '../../../../models/booking-wizard/BulkBookingWizard'
import {VoucherModel} from '../../../../models/svc/VoucherModel'

interface AddBulkProductVoucherProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onAdd: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  productSearchResults?: GlobalSearchModel<ProductModel> | null
  refreshProductsList: (filter?: FilterModel) => void
  productsValues: ProductVoucherBulkModel | null
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  vouchersValues: ProductVoucherBulkModel | null
  searchVouchers: (filter?: FilterModel) => void
}

export const AddBulkProductVoucher = ({
  modalType,
  onModalClose,
  onAdd,
  productSearchResults,
  refreshProductsList,
  productsValues,
  searchVouchers,
  vouchersValues,
  voucherSearchResults,
}: AddBulkProductVoucherProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel | null>(
    modalType === 'product' ? productsValues : vouchersValues
  )
  const [product, setProduct] = useState<ProductModel | null>(null)
  const [voucher, setVoucher] = useState<VoucherModel | null>(null)

  useEffect(() => {
    if (!modalType) {
      setProduct(null)
      setVoucher(null)
    }
  }, [modalType])

  const isProduct = useMemo(() => {
    return modalType === 'product'
  }, [modalType])
  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      if (value) {
        const newValue: ProductVoucherBulkModel = {...value, qty: Number(count)}
        setValue(newValue)
      }
    },
    [value]
  )

  const handleProductChange = useCallback(
    (value) => {
      if (isProduct) {
        setProduct(value)
      }

      if (!isProduct) {
        setVoucher(value)
      }

      const newValue: ProductVoucherBulkModel = {
        code: value.code,
        name: value.name,
        qty: 1,
        isSeated: value?.isSeated ? value?.isSeated : false,
        type: isProduct ? 'product' : 'voucher',
        startedAt: value.startedAt ? value.startedAt : value.productCategory.startedAt,
        endedAt: value.endedAt ? value.endedAt : value.productCategory.endedAt,
        isTimeslot: value.isTimeslot,
        timeslots: [],
      }
      setValue(newValue)
    },
    [isProduct]
  )

  const handleAdd = useCallback(() => {
    if (isProduct && value) onAdd(modalType, value)
    if (!isProduct && value) onAdd(modalType, value)
  }, [isProduct, modalType, onAdd, value])

  const updatedData = useMemo(() => {
    return {...value, qty: value?.qty || 0}
  }, [value])

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      <div className='h-100 d-flex flex-column'>
        <div className='d-flex flex-column flex-grow-1 text-start'>
          <div className='position-absolute top-0 end-0'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
          <h2 className='text-start mb-5'>Select product</h2>
          <div className='mb-5'>
            <FilterSearchableSelectInput
              value={isProduct ? product : voucher}
              itemMapper={itemMapper}
              searchResult={isProduct ? productSearchResults : voucherSearchResults}
              placeholder='Select product'
              onChange={handleProductChange}
              onFilter={isProduct ? refreshProductsList : searchVouchers}
              noMargin
            />
          </div>
          <label className='text-start text-gray-900 mb-5'>Quantity</label>
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            value={updatedData.qty}
            min={0}
          />
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          <Button variant='primary' onClick={handleAdd}>
            <LoadingSpinner loading={false}>Save</LoadingSpinner>
          </Button>
          <Button className='me-1' onClick={onModalClose}>
            Close
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}

const itemMapper = (data: ProductModel | VoucherModel): SelectInputItem => {
  return {
    label: data.name || '',
    value: data.code || '',
  }
}
