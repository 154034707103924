import {toAbsoluteUrl} from '../_metronic/helpers'

export interface ImageConfig {
  src: string
  alt: string
}

export const MainDrawerMenuHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB•N•TECH',
}

export const GPCALogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/gpca.png'),
  alt: 'GPCA',
}

export const EvaInfoPageLogo: ImageConfig = {
  src: toAbsoluteUrl('/favicon-512x512.png'),
  alt: 'WEB·N·TECH',
}

export const PoweredBy: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/Powered-By-WNT.png'),
  alt: 'Powered by WNT',
}

export const Logo404: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const LogoComingSoon: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const EvaBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/dubai-hd.png'),
  alt: 'WEB·N·TECH',
}
export const ArcBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/ARC_BG.png'),
  alt: 'WEB·N·TECH',
}

export const EvaHeaderLogo: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const AuthBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/dubai-hd.png'),
  alt: 'Background',
}

export const AuthFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}

export const CustomerPortalAuthFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'Etihad Airways',
}
export const CustomerRegistrationFormLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/webntech.svg'),
  alt: 'WEB·N·TECH',
}
export const CustomerRegistrationFormLogoHeaderArc: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/Arc_logo.png'),
  alt: 'WEB·N·TECH',
}

export const CustomerPortalAuthBackground: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/dubai-hd.png'),
  alt: 'F1',
}

export const CustomerPortalAuthBackgroundMobile: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/dubai-hd.png'),
  alt: 'F1',
}

export const DefaultFNBProductImage: ImageConfig = {
  src: toAbsoluteUrl('/media/logos/ymc-logo.png'),
  alt: 'YMC',
}

export const DigitalMenuSplashScreenImage: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/f1-etihad-airways-logo.png'),
  alt: 'Etihad Airways',
}

export const SolaaxLogoHeader: ImageConfig = {
  src: toAbsoluteUrl('/media/saas/solaax-logo.png'),
  alt: 'SOLAAX',
}