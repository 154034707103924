import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, select, takeLatest} from 'redux-saga/effects'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ProductCategoryModel} from '../../../../models/retail/ProductCategoryModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GetRetailProductCategories, GetProducts} from './RetailCRUD'
import {ProductModel} from '../../../../models/retail/ProductModel'

interface ActionWithPayload<T> extends Action {
  payload: T
}

interface IPagesState {
  productCategories?: GlobalSearchModel<ProductCategoryModel>
  products?: GlobalSearchModel<ProductModel>
}

const initialAuthState: IPagesState = {
  productCategories: undefined,
}

const actionTypes = {
  SEARCH_PRODUCT_CATEGORY: '[RETAIL] SEARCH PRODUCT CATEGORY',
  SEARCH_PRODUCT_CATEGORY_SUCCESS: '[RETAIL] SEARCH PRODUCT CATEGORY SUCCESS',
  SEARCH_PRODUCT_CATEGORY_FAILED: '[RETAIL] SEARCH PRODUCT CATEGORY FAILED',

  SEARCH_PRODUCT: '[RETAIL] SEARCH PRODUCT',
  SEARCH_PRODUCT_SUCCESS: '[RETAIL] SEARCH PRODUCT SUCCESS',
  SEARCH_PRODUCT_FAILED: '[RETAIL] SEARCH PRODUCT FAILED',
}

export const reducer = persistReducer(
  {storage, key: 'webapp-master', whitelist: []},
  (state: IPagesState = initialAuthState, action: Partial<ActionWithPayload<IPagesState>>) => {
    switch (action.type) {
      case actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS: {
        const productCategories = action.payload?.productCategories
        return {...state, productCategories}
      }
      case actionTypes.SEARCH_PRODUCT_SUCCESS: {
        const products = action.payload?.products
        return {...state, products}
      }
      default:
        return state
    }
  }
)

export const actions = {
  searchProductCategory: () => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY,
  }),
  searchProductCategoryFailed: () => ({type: actionTypes.SEARCH_PRODUCT_CATEGORY_FAILED}),
  searchProductCategorySuccess: (productCategories: GlobalSearchModel<ProductCategoryModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_CATEGORY_SUCCESS,
    payload: {productCategories},
  }),
  searchProduct: () => ({
    type: actionTypes.SEARCH_PRODUCT,
  }),
  searchProductFailed: () => ({type: actionTypes.SEARCH_PRODUCT_FAILED}),
  searchProductSuccess: (products: GlobalSearchModel<ProductModel>) => ({
    type: actionTypes.SEARCH_PRODUCT_SUCCESS,
    payload: {products},
  }),
}

export function* saga() {
  // PRODUCT CATEGORY
  yield takeLatest(actionTypes.SEARCH_PRODUCT_CATEGORY, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters['product-category'])
      const {data} = yield GetRetailProductCategories(filter)
      yield put(actions.searchProductCategorySuccess(data))
    } catch (e) {
      yield put(actions.searchProductCategoryFailed())
    }
  })

  yield takeLatest(actionTypes.SEARCH_PRODUCT, function* refreshEvent() {
    try {
      const filter: FilterModel = yield select((state) => state.system.filters.product)
      const {data} = yield GetProducts(filter)
      yield put(actions.searchProductSuccess(data))
    } catch (e) {
      yield put(actions.searchProductFailed())
    }
  })
}
